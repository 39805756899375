import ContactForm1 from "../../src/components/common/ContactForm1";
import whatsApp from "../images/whatsapp.png";
import call from "../images/telephone.png"
import "./portfolio.css";
import abimg1 from "../images/abImg1.png";
import abimg2 from "../images/abimg2.png";
import abimg3 from "../images/abimg3.png";
import abimg5 from "../images/abimg5.png";
import abimg11 from "../images/abimg11.png";
import mycms from "../images/mycms.png"
import leftMost from "../images/leftmost.png"
import csRightMost from "../images/csRightMost.png"
import { Helmet } from "react-helmet";
import { useState } from "react";
import hrmsname from "../images/hrmsname.png"
import sainaimg from "../images/sainaimg.png"
import vrott from "../images/vrott.png"
// import trimute1 from '../images/trimute1.png'
import saka from '../images/SAKA.png';
import tikus11 from '../images/tikus11.png';
import test111 from '../images/tset111.png';
import ikk1 from '../images/likk1.png';
import jk11 from '../images/jkki1.png';
import custom11 from '../images/custom11.png';
// new logos 
 import webauxil from "../images/webauxlogo.png"
 import webdigil from "../images/webdigilogo.png"
 import websurvinl from "../images/websuvrinlogo.png"
 import webushal from "../images/webushalogo.png"
 import webmajorl from "../images/webmajorlogo.png"
 import webinstal from "../images/webinstalogo.png"
 import webjuniorl from "../images/webjuniorlogo.png"
 import toffle from "../images/touffle.png"
 import objtech from "../images/webobtech.png"
 import weba1 from "../images/weba1.png"
 import weba2 from "../images/weba2.png"
 import weba3 from "../images/weba3.png"
 import weba4 from "../images/weba4.png"
 import weba5 from "../images/weba5.png"
 import weba6 from "../images/weba6.png"
 import weba7 from "../images/weba7.png"
 import weba8 from "../images/weba8.png"
 import weba9 from "../images/weba9.png"


const caseStudies = [
  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1724927291/Group_1000010192_izwiae.png",
    img2: abimg1,
    btnText: "VIEW CASE STUDY",
    link: "/kick-eez",
    alt: "kusheldigi case study-KICK-EEZ",
    category: ["ecommerce"]

  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1724927287/Group_1000010193_geczey.png",
    img2: abimg2,
    btnText: "VIEW CASE STUDY",
    link: "/pro-track",
    alt: "kusheldigi Our Portfolio-PRO",
    category: ["ecommerce"]

  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1724927284/Group_1000010194_a1kfrj.png",
    img2: abimg3,
    btnText: "VIEW CASE STUDY",
    link: "/rely",
    alt: "case study-rely",
    category: ["ecommerce"]

  },

  {
    img: " https://res.cloudinary.com/dd9tagtiw/image/upload/v1725880369/image_2_jkmhgr.png",
    img2: abimg5,
    btnText: "VIEW CASE STUDY",
    link: "/solenoid-ninja",
    alt: "case study-Solenoid Ninja",
    category: ["ecommerce"]

  },


  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886340/Group_1171275953_ylodxi.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725952762/image_75_ywf6np.svg",
    btnText: "VIEW CASE STUDY",
    link: "/ballistic",
    alt: "case study-Speck",
    category: ["ecommerce"]

  },
  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886339/image_5_ds431l.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725953651/image_18_1_j5e3am.svg",
    btnText: "VIEW CASE STUDY",
    link:"/sites",
    // target: "_blank",
    alt: "case study-Karol Bagh",
    category: ["ecommerce"]


  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886338/image_6_s5p9p0.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725953729/logo-white_1_1689966883__67774_1_yqlhdf.svg",
    btnText: "VIEW CASE STUDY",
    link: "/TopBrass",
    alt: "case study-Slides365",
    category: ["ecommerce"]

  },


  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886339/Group_1000010185_1_h1dk25.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725953057/slide_1_gelwuz.svg",
    btnText: "VIEW CASE STUDY",
    // link:"https://chingsingh.com/",
    target: "_blank",
    alt: "case study-Chingsingh",
    category: ["ecommerce"]


  },
  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886339/image_4_yur4tj.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725953594/Group_1171275966_1_pjkfeq.svg",
    btnText: "VIEW CASE STUDY",
    link:"/Ai",
    alt: "case study-Hazoorilal",
    category: ["ecommerce"]


  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886340/Group_1171275991_nrwbso.png",
    btnText: "VIEW CASE STUDY",
    link: "/crazyCMS",
    img2: mycms,
    alt: "case study-CEO Wheels",
    category: ["website"]


  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886340/Group_1171275950_eddxtx.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725952623/Layer_1_15_ilqoor.svg",
    btnText: "VIEW CASE STUDY",
    alt: "case study-Granny's finest",
    category: ["website"] ,
    // link: "/fairChildFun",

  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886339/Group_1171275960_jrfrmk.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725952841/image_76_tovttv.svg",
    btnText: "VIEW CASE STUDY",
    link: "/woodView",
    alt: "case study-Bulletproof",
    category: ["website"]

  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886339/Group_1171275992_lfqyjn.png",
    img2: hrmsname,
    btnText: "VIEW CASE STUDY",
    // link:"https://skknbykim.com/",
    target: "_blank",
    // alt:"case study-skkn",
    category: ["website"]


  },

  {
    img: "https://res.cloudinary.com/dd9tagtiw/image/upload/v1725886340/loginImage.bf19a61a3e8065a38934_kqnphz.png",
    img2: "https://res.cloudinary.com/ecommerce-website/image/upload/v1725952993/SHUBHALLOYS_j2i6dz.svg",
    btnText: "VIEW CASE STUDY",
    link: "/subhAlloy",
    alt: "case study-Woolrich",
    category: ["website"]

  },

  {
    img: jk11,

    img2: custom11,
    btnText: "VIEW LIVE SITE",
    // link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["website"]
  },



  {
    img: weba1,

    img2: webinstal,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba2,

    img2: objtech,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba3 , 

    img2: abimg11,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba4,

    img2: webauxil,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba5,

    img2: webjuniorl,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba6,

    img2: websurvinl,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba7,

    img2: toffle,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba8,

    img2: webmajorl,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },
  {
    img: weba9,

    img2: webushal,
    // btnText: "VIEW CASE STUDY",
    link: "/Digitalmna",
    alt: "case study-DigitalMnA Partners",
    category: ["software"]
  },



  {
    img: sainaimg,
    // img2: "",
    btnText: "VIEW LIVE SITE",
    // link: "/Slidesdesign",
    alt: "case study-Slides365",
    category: ["appdevelop"],
   id:"st",
   id1:"st1"
  },

  {
    img:saka,
    // img2: "",
    btnText: "VIEW LIVE SITE",
    // link: "/Slidesdesign",
    alt: "case study-Slides365",
    category: ["appdevelop"],
   id:"st",
   id1:"st1"
  },

  {
    img:tikus11,
    // img2: "",
    btnText: "VIEW LIVE SITE",
    // link: "/Slidesdesign",
    alt: "case study-Slides365",
    category: ["appdevelop"],
   id:"st",
   id1:"st1"
  },
  {
    img:test111,
    // img2: "",
    btnText: "VIEW LIVE SITE",
    // link: "/Slidesdesign",
    alt: "case study-Slides365",
    category: ["appdevelop"],
   id:"st",
   id1:"st1"
  },
  {
    img:ikk1,
    // img2: "",
    btnText: "VIEW LIVE SITE",
    // link: "/Slidesdesign",
    alt: "case study-Slides365",
    category: ["appdevelop"],
   id:"st",
   id1:"st1"
  },

  {
    img: vrott,
    btnText: "VIEW LIVE SITE",
    // link: "/Slidesdesign",
    alt: "case study-Slides365",
    category: ["appdevelop"],
    id:"st",
    id1:"st1"
  },
 
  
];

const Portfolio = () => {

  const phoneNumber = "9045301702";

  const whatAppHandler = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const callHandler = () => {
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl, "_blank");
  }

  const topData = [
    {
      num: "12+",
      title: "Years on the Market",
    },
    {
      num: "90+",
      title: "Experts on Board",
    },
    {
      num: "300+",
      title: "Complete Projects",
    },
  ];

 
  const [filteredCategory, setFilteredCategory] = useState("All");

  const handleFilterChange = (category) => {
    setFilteredCategory(category);
  };

  const filteredCaseStudies = filteredCategory === "All"? caseStudies: caseStudies.filter((data) => data.category.includes(filteredCategory.toLowerCase()));



  return (
    <>

      <Helmet>
        <title>Case studies of KushelDigi Solutions</title>
        <meta
          name="description"
          content="Kushel digi has been serving its clients for over 6 years, earning the reputation of a trusted IT partner. Browse some of our success stories."
        />
        <link rel="canonical" href="https://www.kusheldigi.com/portfolio" />
      </Helmet>

      <div className="ser-main">

        {/* second section  */}
        <div className="nwPortSec2">

          <img src={leftMost} className="leftMost" alt=" portfolio-design" title=" portfolio-design" />

          <div className="nwPortSec2Cont">
            {/* top  */}

            {/* bottom */}

            <div className="mwPS2_bttom">

              <img src={csRightMost} className="csRightMost" alt=" portfolio-design" title=" portfolio-design" />

              <h2 className="nwPs2head">Our Work</h2>
              <div className="our_btnii">
                <button onClick={() => handleFilterChange("All")} className={`${filteredCategory === "All" && "addbgcclds"}`}>All</button>
                <button onClick={() => handleFilterChange("ecommerce")} className={`${filteredCategory === "ecommerce" && "addbgcclds"}`}>E-Commerce</button>
                <button onClick={() => handleFilterChange("website")} className={`${filteredCategory === "website" && "addbgcclds"}`}>Custom Software</button>
                <button onClick={() => handleFilterChange("software")} className={`${filteredCategory === "software" && "addbgcclds"}`}>Website</button>
                <button onClick={() => handleFilterChange("appdevelop")} className={`${filteredCategory === "appdevelop" && "addbgcclds"}`}>Mobile Apps</button>
              </div>
              <div className="mwPS2_boxes">

                {filteredCaseStudies.map((data, index) => (
                  <div id={data?.id1} className="sinCaseStudy" key={index}>
                    <img src={data.img} className="sinCaseStudyimg" alt={data.alt} title={data.alt} />
                    <div className="csInsiImg">
                      {
                        data?.img2 ?
                      <img src={data?.img2} alt={data?.alt} title={data?.alt} />
                      :
                      <p></p>
                      }
                      <a target={data.target} href={data.link}>
                      {
                        data?.btnText && 
                      
                        <button id={data?.id}  className="viewCSbTN">
                          <span>{data?.btnText}</span>
                          <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.175 9H0V7H12.175L6.575 1.4L8 0L16 8L8 16L6.575 14.6L12.175 9Z" fill="#101010" />
                          </svg>
                        </button>
}
                      </a>
                    </div>
                  </div>
                ))}
              </div>

            </div>

          </div>
        </div>

        <ContactForm1 />

        <div className="whtsApBtns">
          <button onClick={whatAppHandler}>
            <img className="what-image-universal" src={whatsApp} alt="whatsApp-kusheldigi" title="whatsApp-kusheldigi" />
          </button>
          <button onClick={callHandler}>
            <img src={call} alt="call-icon" title="call-icon" />
          </button>

        </div>


      </div>
    </>
  );
};

export default Portfolio;
